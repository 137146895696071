<template>
  <div>
    <!-- Top Buttons -->
    <b-row>
      <b-col>
        <b-button
          variant="danger"
          class="my-2"
          @click.prevent="handleAddNewAdminUser"
        >
          <feather-icon
            icon="UserPlusIcon"
            class="mr-50"
          />
          Create New Admin User
        </b-button>
      </b-col>
      <b-col>
        <b-dropdown
          id="filter-by"
          :split-variant="filterByProp.outline"
          :text="filterByProp.text"
          :variant="filterByProp.variant"
          class="float-right my-2"
          split
        >
          <b-dropdown-item
            v-for="(item, index) in filterByOptions"
            :key="index"
            @click.prevent="handleFilterBy(item)"
          >
            <span>{{ item.text }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- table -->
    <my-table
      title=""
      :rows="rows"
      :columns="columns"
      :is-loading="isLoading"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'ability.title'">
          <b-badge
            :variant="props.row.ability.title ? 'success' : 'danger'"
            disabled
            class="w-100 font-weight-bolder badge-padding"
          >{{ props.row.ability.title || 'No Access' }}</b-badge>
        </span>
        <span v-else-if="props.column.field === 'status.value'">
          <b-badge
            :variant="props.row.status.variant"
            disabled
            class="w-100 font-weight-bolder badge-padding"
          >{{ props.row.status.text }}</b-badge>
        </span>
        <span v-else-if="props.column.field === 'date_added'">
          {{ $helpers.formatDate(props.row.date_added) }}
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click.prevent="handleEditUser(props.row)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit User</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>
    </my-table>
    <AddEditAdminUserModal
      :is-show="showAddEditUserModal"
      :form="selectedUser"
      :is-edit="isEdit"
      :abilities="abilities"
      @reset-modal="handleResetModal"
      @success="handleSuccess"
    />
  </div>
</template>

<script>
import axios from '@axios'
import { MyTable, AddEditAdminUserModal } from '@/components'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    MyTable,
    AddEditAdminUserModal,
  },
  data() {
    return {
      isLoading: false,
      showAddEditUserModal: false,
      selectedUser: {},
      isEdit: false,
      filterBy: 'all',
      filterByOptions: [
        { value: 'all', text: 'All' },
        { value: 'active', text: 'Active' },
        { value: 'inactive', text: 'Inactive' },
      ],
      columns: [
        {
          label: 'ID',
          field: 'id',
          thClass: 'text-left align-middle',
          tdClass: 'text-left',
          type: 'number',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Date Created',
          field: 'date_added',
        },
        {
          label: 'Authority',
          field: 'ability.title',
        },
        {
          label: 'Status',
          field: 'status.value',
          thClass: 'text-left align-middle',
          tdClass: 'text-left',
          type: 'number',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      abilities: [],
    }
  },
  computed: {
    filterByProp() {
      if (this.filterBy === 'active') {
        return {
          text: 'Active',
          outline: 'outline-success',
          variant: 'success',
        }
      }

      if (this.filterBy === 'inactive') {
        return {
          text: 'Inactive',
          outline: 'outline-warning',
          variant: 'warning',
        }
      }

      return {
        text: 'All',
        outline: 'outline-primary',
        variant: 'primary',
      }
    },
  },
  created() {
    this.fetchAdmins()
    this.fetchAbilities()
  },
  methods: {
    async fetchAdmins() {
      this.isLoading = true
      const response = await axios.get(`/admin-access?filter_by=${this.filterBy}`)
      if (response.status === 200) {
        this.rows = response.data.filter(data => data.id !== getUserData().id)
      }
      this.isLoading = false
    },
    async fetchAbilities() {
      const response = await axios.get('/admin-abilities')
      if (response.status === 200) {
        this.abilities = response.data
      }
    },
    handleEditUser(row) {
      this.selectedUser = {
        id: row.id,
        name: row.name,
        email: row.email,
        ability_id: row.ability.id,
        status: row.status.value,
      }
      this.showAddEditUserModal = true
      this.isEdit = true
    },
    handleSuccess() {
      this.fetchAdmins()
    },
    handleResetModal() {
      this.showAddEditUserModal = false
      this.selectedUser = {}
      this.isEdit = false
    },
    handleAddNewAdminUser() {
      this.showAddEditUserModal = true
    },
    handleFilterBy(selected) {
      this.filterBy = selected.value
      this.fetchAdmins()
    },
  },
}
</script>
